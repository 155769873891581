export const errors = {
  errorFallback: {
    page: 'Error',
    title: '¡Vaya! Algo salió mal',
    subtitle:
      'Estamos trabajando en ello, por favor intenta de nuevo en unos minutos.',
    refresh: 'Recargar la página',
  },
  undefined: 'El servicio no está disponible, por favor intente más tarde.',
  unauthorized: 'No estás autorizado para realizar esta acción',
  database: 'Ocurrió un error en la respuesta de la base de datos:',
  REQUIRED_FIELD: 'Este campo es obligatorio',
  BANKS_BIN_ALREADY_EXISTS: 'El bin ya existe',
  BANKS_CODE_ALREADY_EXISTS_ON_DB:
    'El código del banco ya existe, por favor asigne un código diferente',
  BANKS_NAME_ALREADY_EXISTS_ON_THIS_COUNTRY:
    'El nombre del banco ya existe en {{country}}',
  BILLBOARDS_BILLBOARD_ID_URL_PARAMS:
    'no se encontró la cartelera con el ID {{billboardId}}',
  BILLBOARDS_BODY_VALIDATION: 'objeto no recibido',
  BILLBOARDS_NOT_FOUND: 'no se encontró la cartelera con el ID {{billboardId}}',
  BILLBOARDS_QUERY_PROCESS: 'error de consulta',
  BILLBOARDS_REQUEST_VALIDATION: 'error de validación',
  BILLBOARDS_SELECT_AT_LEAST_ONE_EVENT:
    'debes de seleccionar al menos un evento',
  BILLBOARDS_STATEMENT_PROCESS: 'error de validación',
  BILLBOARDS_STATUS_URL_PARAMS: 'no se recibió la propiedad de estatus',
  BILLBOARDS_SUBDOMAIN_EXISTS: 'Este subdominio ya existe para otra cartelera',
  BILLBOARDS_SUBDOMAIN_URL_PARAMS: 'no se recibió la propiedad del subdominio',
  BILLBOARDS_UPLOADING_IMAGE: 'ocurrió un problema al subir la imagen',
  BILLBOARDS_USER_ID_HEADER: 'no se recibió el identificador del usuario',
  BOOKINGS_EVENT_DATA_NIL: 'no se cuenta con información suficiente',
  BOOKINGS_EVENT_ID_TICKET_TYPES_NIL: 'el evento no tiene boletos',
  BOOKINGS_QUANTITY_ERROR: 'la cantidad no debe ser mayor que 250',
  BOOKINGS_TICKET_TYPES_QUANTITY: 'no hay tipos de boletos disponibles',
  BOOKINGS_REQUEST_VALIDATION: 'error de validación',
  BOOKINGS_QUERY_PROCESS: 'error de consulta',
  BOOKINGS_BODY_VALIDATION: 'no se recibió un objeto para realizar la acción',
  BOOKINGS_STATEMENT_PROCESS: 'error de validación',
  BOOKINGS_TICKET_TYPE_DOES_NOT_EXIST: 'el boleto no existe ',
  BOOKINGS_QUANTITY_VALIDATION: 'la cantidad debe ser diferente de cero',
  BOOKINGS_HOLD_AND_BATCH_VALIDATION:
    'la cantidad entre el hold y el batch es diferente',
  BOOKINGS_RESERVATION_WAS_NOT_CREATED: 'la reservación no fue creada',
  CHARGE_AMOUNT_LIMIT_EXCEEDED:
    'el monto excede el límite permitido de $250,000.00',
  CHECKPOINTS_BODY_VALIDATION:
    'no se recibió un objeto para realizar la acción',
  CHECKPOINTS_CHECKPOINT_WAS_NOT_FOUND: 'el checkpoint no fue encontrado',
  CHECKPOINTS_EVENT_ID_URL_PARAMS: 'no se recibió el id del evento',
  COMMISSIONS_COMMISSIONS_ALREADY_CREATED_FOR_EVENT:
    'este evento ya tiene comisiones',
  COMMISSIONS_COMMISSIONS_ALREADY_CREATED_FOR_USER:
    'este usuario ya tiene comisiones',
  COMMISSIONS_COMMISSION_BY_DEFAULT:
    'no existen las comisiones predeterminadas',
  COMMISSIONS_COMMISSION_WAS_NOT_CREATED: 'no fue posible crear la comisión',
  COMMISSIONS_EVENT_ID_URL_PARAMS: 'no se recibió el id del evento',
  COMMISSIONS_REQUEST_VALIDATION: 'error de validación',
  COMMISSIONS_STATEMENT_PROCESS: 'no fue posible realizar la acción',
  COMMISSIONS_USER_ID_URL_PARAMS: 'no se recibió el id del usuario',
  EMAIL_SENDER_CAN_NOT_SEND_AN_EMAIL: 'no fue posible enviar el correo',
  EVENTS_ABOUT_VALIDATION: 'debes de agregar la descripción del evento',
  EVENTS_ADDRESS_VALIDATION:
    'debes de agregar la dirección del lugar del evento',
  EVENTS_BODY_EVENT_IDS: 'no se recibieron los identificadores de los eventos',
  EVENTS_BODY_VALIDATION: 'objeto no recibido',
  EVENTS_BOOKINGS_COMPLETE_CAN_NOT_ASSIGN_MAP:
    'este evento ya tiene boletos vendidos o apartados no se puede asignar un mapa',
  EVENTS_BOOKINGS_COMPLETE_CAN_NOT_MODIFY_FEE_BREAKDOWN:
    'este evento ya tiene boletos vendidos o apartados no se puede modificar el tipo de tarifa',
  EVENTS_BOOKINGS_COMPLETE_CAN_NOT_UNASSIGN_MAP:
    'este evento ya tiene boletos vendidos o apartados no se puede desasignar un mapa',
  EVENTS_CHANNEL_TYPE_VALIDATION:
    'el tipo de transmisión solo puede ser digital o en sitio',
  EVENTS_COLLABORATOR_ID_URL_PARAMS:
    'no se recibió el identificador del colaborador',
  EVENTS_COPING_IMAGE: 'ocurrió un problema al copiar la imagen',
  EVENTS_DELETING_IMAGE: 'ocurrió un problema al eliminar la imagen',
  EVENTS_EVENT_DOES_NOT_EXIST: 'este evento no existe',
  EVENTS_EVENT_DOES_NOT_HAVE_PAYMENT_METHODS_ACTIVE:
    'este evento no tiene métodos de pago activos',
  EVENTS_EVENT_DOES_NOT_HAVE_TICKET_TYPES_ACTIVE:
    'este evento no tiene tipos de boleto activos',
  EVENTS_EVENT_ENDED: 'este evento ha finalizado y no se puede activar',
  EVENTS_EVENT_ID_URL_PARAMS: 'no se recibió el identificador del evento',
  EVENTS_FILTER_QUERY_PARAMS: 'no se recibió la propiedad filtro',
  EVENTS_HAS_COMPLETE_BOOKING_CHANNEL_TYPE:
    'este evento ya tiene boletos vendidos o apartados no se puede modificar el medio de transmisión',
  EVENTS_HAS_COMPLETE_BOOKING_CURRENCY:
    'este evento ya tiene boletos vendidos o apartados no se puede modificar el tipo de moneda',
  EVENTS_HAS_COMPLETE_BOOKING_EVENT_TYPE:
    'este evento ya tiene boletos vendidos o apartados no se puede modificar el tipo de evento',
  EVENTS_HAS_COMPLETE_BOOKING_SUBCATEGORY:
    'este evento ya tiene boletos vendidos o apartados no se puede modificar la subcategoría',
  EVENTS_INCLUDED_URL_PARAMS: 'no se recibió la propiedad incluido',
  EVENTS_MAP_ID_URL_PARAMS: 'no se recibió el identificador del mapa',
  EVENTS_MAX_TICKETS_PER_BOOKING:
    'máximo de tickets por booking debe ser un valor entre 1 y 10',
  EVENTS_PREFERENCE_FEE_BREAKDOWN_VALIDATION:
    "el tipo de tarifa solo puede ser 't' o 'f' ",
  EVENTS_PROMOTION_BUNDLE_RULE_COMPARITION_VALIDATION:
    'el primer valor de tu promoción debe ser mayor al segundo',
  EVENTS_PROMOTION_BUNDLE_RULE_FIRST_ELEMENT_VALIDATION:
    'el primer carácter debe ser numérico',
  EVENTS_PROMOTION_BUNDLE_RULE_SECOND_ELEMENT_VALIDATION:
    'el segundo carácter deber ser numérico',
  EVENTS_PROMOTION_BUNDLE_RULE_VALIDATION:
    'debes de agregar una promoción con este formato: 2x1',
  EVENTS_PROMOTION_CAN_NOT_BE_NULL_OBJECT:
    'debes de mandar una promoción correcta',
  EVENTS_PROMOTION_CODE_ALREADY_EXISTS:
    'este código de promoción ya ha sido utilizado para este evento',
  EVENTS_PROMOTION_EXPIRATION_LIMIT_VALIDATION:
    'el límite de uso de la promoción debe ser mayor que 0',
  EVENTS_PROMOTION_FLAT_RULE_VALIDATION:
    'el importe de descuento debe ser mayor o igual a 0',
  EVENTS_PROMOTION_ID_URL_PARAMS:
    'no se recibió el identificador de la promoción',
  EVENTS_PROMOTION_PERCENTAGE_RULE_VALIDATION:
    'el porcentaje de descuento debe de ser un valor entre el 1 y el 100',
  EVENT_PROMOTION_SERVICE_ERROR:
    'El código ya existe, por favor asigne un código diferente',
  EVENTS_PROMOTION_TICKET_TYPE_VALIDATION: 'el tipo de boleto no existe',
  EVENTS_PROMOTION_USED:
    'no se puede eliminar la promoción porque ya ha sido utilizada',
  EVENTS_PROMOTION_WAS_NOT_CREATED: 'no se creó la promoción',
  EVENTS_QUERY_PROCESS: 'error en la consulta',
  EVENTS_REQUEST_VALIDATION: 'error de validación',
  EVENTS_SPONSOR_ID_URL_PARAMS:
    'no se recibió el identificador del patrocinador',
  EVENTS_STATEMENT_PROCESS: 'error de validación',
  EVENTS_SUBDOMAIN_EXIST: 'este subdominio ya existe para otro evento',
  EVENTS_SUBDOMAIN_URL_PARAMS: 'no se recibió el subdominio',
  EVENTS_TICKETS_URL_PARAMS: 'no se recibió la propiedad tickets',
  EVENTS_TICKET_ID_URL_PARAMS: 'no se recibió el identificador del ticket',
  EVENTS_UPLOADING_IMAGE: 'ocurrió un problema al subir la imagen',
  EVENTS_USER_DOES_NOT_EXIST: 'este usuario no existe',
  EVENTS_USER_ID_CAN_NOT_BE_NULL_OR_ZERO:
    'el identificador del usuario tiene que ser mayor que 0',
  EVENTS_USER_ID_HEADER: 'no se recibió el identificador del usuario',
  EVENTS_USE_MAP_URL_PARAMS:
    'no se recibió la propiedad de si usa mapa este evento',
  EVENTS_VENUE_VALIDATION: 'debes de agregar el lugar del evento',
  FETCH_ERROR: 'ocurrió un error al obtener los datos',
  FORMS_DEFAULT_ERROR: 'ocurrió un error',
  FORMS_EVENT_NOT_FOUND: 'no se encuentra el evento',
  FORMS_FIELD_OPTIONS_JSON_ITEMS_REQUIRED:
    'el valor del campo options no es un json',
  FORMS_FIELD_OPTIONS_REQUIRED:
    'el campo options es obligatorio cuando el campo tipo es select',
  FORMS_GET_DATA_ERROR: 'error al obtener datos',
  FORMS_GET_EVENT_FROM_API_ERROR:
    'ocurrió un error al obtener el evento desde la api',
  FORMS_LIST_TICKET_TYPE_ERROR:
    'ocurrió un error al obtener los  tipos de tickets',
  FORMS_MAXIMUN_NUMBER_QUESTIONS_REACHED:
    'se alcanzó el número máximo permitido de preguntas',
  FORMS_PREFERENCE_NOT_FOUND: 'no se encuentra la preferencia',
  FORMS_REQUEST_DATA_INVALID:
    'uno o varios campos en el body de la petición son inválidos',
  FORMS_SOME_OPTION_VALUE_EMPTY:
    'todos los items en el campo options tienen que tener valor',
  FORMS_SOME_TICKET_NOT_FOUND: 'no se encontró el ticket',
  FORMS_STATEMENT_PROCESS: 'error de consulta',
  FORMS_URL_PARAM_EVENT_ID_INVALID: 'evento id en la url es inválido',
  HOLDS_GENERAL_AREA:
    'Por ahora zonas generales no están disponibles para holds',
  PAYMENTS_ALREADY_CREATED: 'este evento ya tiene métodos de pago',
  PAYMENTS_BODY_VALIDATION: 'objeto no recibido',
  PAYMENTS_DO_NOT_EXIST: 'no existen los métodos de pago',
  PAYMENTS_DO_NOT_EXIST_BY_DEFAULT:
    'no existen los métodos de pago predeterminados',
  PAYMENTS_EVENT_IDS_URL_PARAMS:
    'no se recibieron los identificadores de los eventos',
  PAYMENTS_EVENT_ID_HEADER: 'no se recibió el identificador del evento',
  PAYMENTS_EVENT_ID_URL_PARAMS: 'no se recibió el identificador del evento',
  PAYMENTS_QUERY_PROCESS: 'error de consulta',
  PAYMENTS_REQUEST_VALIDATION: 'error de validación',
  PAYMENTS_STATEMENT_PROCESS: 'error de validación',
  PAYMENTS_TICKET_TYPE_NOT_FOUND: 'el método de pago no existe',
  PAYMENTS_USER_NOT_FOUND: 'este usuario no existe',
  PAYMENTS_WAS_NOT_CREATED: 'el método de pago no fue creado',
  REPORTER_BOOKING_NOT_FOUND: 'no se encuentra la reservación',
  REPORTER_CUSTOM_CLIENT_EMAIL_EMPTY:
    'el correo electrónico del cliente se encuentra vacío',
  REPORTER_DEFAULT_ERROR: 'ocurrió un error',
  REPORTER_EVENT_NOT_FOUND: 'no se encuentra el evento',
  REPORTER_GENERATE_PDF_FILE_ERROR: 'error al generar el archivo pdf',
  REPORTER_GENERATE_XLSX_FILE_ERROR: 'error al generar el archivo xlsx',
  REPORTER_GET_BOOKING_FROM_API_NOT_FOUND:
    'no se encontró la reservación en la api',
  REPORTER_GET_DATA_ERROR: 'error al obtener datos',
  REPORTER_GET_TICKET_TYPES_FROM_API_ERROR:
    'ocurrió un error al obtener los tipos de tickets',
  REPORTER_QUERY_STRING_INVALID:
    'uno o varios campos en las query string de la petición son inválidos',
  REPORTER_REQUEST_DATA_INVALID:
    'uno o varios campos en el body de la petición son inválidos',
  REPORTER_REQUEST_HEADER_INVALID:
    'uno o varios campos en las cabeceras de la petición son inválidos',
  REPORTER_SAVE_FILE_ERROR: 'error al guarda el archivo',
  REPORTER_SEND_EMAIL_ERROR: 'error al enviar correo electrónico',
  REPORTER_STATEMENT_PROCESS: 'error de consulta',
  REPORTER_URL_PARAM_BOOKING_ID_INVALID: 'reservación id en la url es inválido',
  REPORTER_URL_PARAM_EVENT_ID_INVALID: 'evento id en la url es inválido',
  REPORTER_URL_PARAM_EVENT_OR_BOOKING_ID_INVALID:
    'evento id o reservación id en la url es o son inválidos',
  REPORTER_USER_NOT_FOUND: 'no se encuentra el usuario',
  RESERVATION_CREATING_ERR:
    'el boleto seleccionado ya no cuenta con disponibilidad para generar una cortesía, por favor intente con otro',
  SEATS_CREATE_EVENT_ON_SEATS_IO_API_ERROR:
    'error al crear el evento en seats.io',
  SEATS_DEFAULT_ERROR: 'ocurrió un error',
  SEATS_GET_SEAT_EVENT_KEY_FROM_EVENT_API_ERROR:
    'error obteniendo la llave del evento para seats.io',
  SEATS_HEADER_USE_ID_INVALID: 'user id en las cabeceras es inválido',
  SEATS_IDENTIFIER_NOT_FOUND_SEAT_CHANGE_STATUS_ON_SEATS_IO_API_ERROR:
    'no se encontró el identificador del asiento en seats.io',
  SEATS_MAP_NOT_FOUND: 'mapa no encontrado',
  SEATS_MAP_OR_EVENT_NOT_FOUND: 'mapa o evento no se encuentran',
  SEATS_REMOVE_EVENT_ON_SEATS_IO_API_ERROR:
    'error al eliminar un evento en seats.io',
  SEATS_REQUEST_DATA_INVALID:
    'uno o varios campos en el body de la petición son inválidos',
  SEATS_RESPONSE_CREATE_EVENT_KEY_ON_SEATS_IO_API_IS_EMPTY:
    'respuesta al crear evento en seats.io esta vacía',
  SEATS_RESULT_IS_EMPTY: 'resultado vacío',
  SEATS_SEAT_BOOKED_ON_SEATS_IO_API_ERROR:
    'error al establecer en booked un asiento en seats.io',
  SEATS_SEAT_CHANGE_STATUS_ON_SEATS_IO_API_ERROR:
    'error al cambiar el estado de un asiento en seats.io',
  SEATS_STATEMENT_PROCESS: 'error de consulta',
  SEATS_URL_PARAM_EVENT_ID_INVALID:
    'evento id en los parámetros de la url es inválido',
  SEATS_URL_PARAM_HOLD_ID_INVALID:
    'hold id en los parámetros de la url es inválido',
  SEATS_URL_PARAM_MAP_ID_INVALID:
    'map id en los parámetros de la url es inválido',
  SEATS_URL_QUERY_STR_HOLDS_ID_INVALID:
    'hold id en la query string es inválido',
  SEATS_HOLD_HAS_LINKS: 'El hold tiene links asociados',
  SEATS_HOLD_HAS_BATCH: 'El hold tiene cortesías asociadas',
  TICKET_TYPES_ASSOCIATED_FORM: 'el boleto tiene un formulario asociado',
  TICKET_TYPES_ASSOCIATED_PROMOTION: 'el boleto tiene una promoción asociada',
  TICKET_TYPES_BODY_VALIDATION: 'error de validación',
  TICKET_TYPES_DATA_NULL_PROCESS: 'no se encontró información',
  TICKET_TYPES_EVENT_ID_URL_PARAMS:
    'error obteniendo el identificador del evento en los parámetros de la url',
  TICKET_TYPES_HOLD_ID_URL_PARAMS:
    'error obteniendo el identificador del hold en los parámetros de la url',
  TICKET_TYPES_ID_URL_PARAMS:
    'error obteniendo el identificador del boleto en los parámetros de la url',
  TICKET_TYPES_NAME_ALREADY_EXIST: 'error nombre de boleto ya existe',
  TICKET_TYPES_QUERY_PROCESS: 'error formando la consulta',
  TICKET_TYPES_REQUEST_VALIDATION:
    'Intentelo más tarde, servicio no disponible.',
  TICKET_TYPES_STATEMENT_PROCESS: 'error de consulta',
  TICKET_TYPES_USED_IN_RESERVATIONS: 'error boleto usado en reservaciones',
  TICKET_TYPES_WRONG_STATUS:
    'error obteniendo el identificador del evento en los parámetros de la url',
  TICKET_TYPES_ZERO_EVENT_ID: 'error imposible query sin evento mayor a cero',
  USERS_USER_ID_URL_PARAMS: 'no se recibió identificador del usuario',
  USERS_ZIP_CODE_URL_PARAMS: 'no se recibió el identificador del código postal',
  USERS_FILTER_IS_EMPTY: 'no se recibió la propiedad filtro',
  USERS_PASSWORD_EMPTY: 'no se puede cifrar la contraseña',
  USERS_QUERY_PROCESS: 'error de consulta',
  USERS_DATA_NULL_PROCESS: 'no se encontró al usuario',
  USERS_USER_EMAIL_URL_PARAMS: 'no se recibió el correo electrónico',
  USERS_DOES_NOT_EXIST:
    'El usuario con el que intentas iniciar sesión no existe',
  USERS_REQUEST_VALIDATION: 'error de validación',
  USERS_BODY_VALIDATION: 'no se recibió un objeto para realizar la acción',
  USERS_STATEMENT_PROCESS: 'error de consulta',
  USERS_TRANSACTION_PROCESS: 'error de transacción',
  USERS_BUCKET_WITHOUT_INITIATING: 'error interno',
  USERS_UPLOADING_IMAGE: 'ocurrió un error al actualizar la imagen',
  USERS_DELETING_IMAGE: 'ocurrió un error al eliminar la imagen',
  USERS_SENDING_EMAIL: 'ocurrió un error al enviar el email',
  USERS_COGNITO_USERNAME_EXISTS: 'el usuario ya existe',
  USERS_COGNITO_ALIAS_EXISTS: 'el email o teléfono ya existen',
  USERS_COGNITO_CODE_DELIVERY_FAILURE:
    'falló el envío de código de verificación',
  USERS_COGNITO_CODE_MISMATCH: 'la información proporcionada es incorrecta',
  USERS_COGNITO_CONCURRENT_MODIFICATION: 'ocurrieron dos o mas modificaciones',
  USERS_COGNITO_DUPLICATE_PROVIDER: 'este proveedor ya ha sido agregado',
  USERS_COGNITO_ENABLE_SOFTWARE_TOKEN: 'error de autenticación',
  USERS_COGNITO_EXPIRED_CODE: 'el código expiro',
  USERS_COGNITO_GROUP_EXISTS: 'el grupo ya existe',
  USERS_COGNITO_INTERNAL_ERROR: 'error interno',
  USERS_COGNITO_INVALID_EMAIL_ROLE_ACCESS_POLICY:
    'no se pudo autenticar tu email',
  USERS_COGNITO_INVALID_LAMBDA_RESPONSE_EXCEPTION: 'respuesta invalida',
  USERS_COGNITO_INVALID_OAUTH_FLOW: 'error de autenticación',
  USERS_COGNITO_INVALID_PARAMETER: 'parámetro inválido',
  USERS_COGNITO_INVALID_PASSWORD: 'contraseña invalida',
  USERS_COGNITO_INVALID_SMS_ROLE_ACCESS_POLICY:
    'el rol proporcionado no tiene permisos',
  USERS_COGNITO_INVALID_SMS_ROLE_TRUST_RELATIONSHIP:
    'la configuración de SMS para este rol no es válida',
  USERS_COGNITO_USER_POOL_CONFIGURATION:
    'la configuración de usuario no es valida',
  USERS_COGNITO_LIMIT_EXCEED: 'se excedió el límite de solicitudes',
  USERS_COGNITO_MFA_METHOD_NOT_FOUND:
    'no se encontró el método de autenticación',
  USERS_COGNITO_NOT_AUTHORIZED: 'correo electrónico o contraseña incorrectos',
  USERS_COGNITO_PASSWORD_RESET_REQUIRED: 'se requiere resetear la contraseña',
  USERS_COGNITO_PRECONDITION_NOT_MET: 'no se cumplió la condición',
  USERS_COGNITO_RESOURCE_NOT_FOUND: 'no se encontró el recurso solicitado',
  USERS_COGNITO_SCOPE_DOES_NOT_EXIST: 'no existe el alcance',
  USERS_COGNITO_SOFTWARE_TOKEN_MFA_NOT_FOUND: 'error al realizar la validación',
  USERS_COGNITO_TOO_MANY_FAILED_ATTEMPTS: 'se excedió el límite de intentos',
  USERS_COGNITO_TOO_MANY_REQUEST: 'se excedió el límite de solicitudes',
  USERS_COGNITO_UNAUTHORIZED: 'no se autorizó la solicitud',
  USERS_COGNITO_UNEXPECTED_LAMBDA: 'error al realizar la validación',
  USERS_COGNITO_UNSUPPORTED_IDENTITY_PROVIDER: 'identificador no soportado',
  USERS_COGNITO_UNSUPPORTED_OPERATION: 'operación no valida',
  USERS_COGNITO_UNSUPPORTED_TOKEN_TYPE: 'token no soportado',
  USERS_COGNITO_UNSUPPORTED_USER_STATE: 'el usuario no es valido',
  USERS_COGNITO_USER_IMPORT_IN_PROGRESS: 'error al modificar usuario',
  USERS_COGNITO_USER_LAMBDA_VALIDATION: 'error al realizar la validación',
  USERS_COGNITO_USER_NOT_CONFIRMED:
    'el usuario no se ha confirmado exitosamente',
  USERS_COGNITO_USER_NOT_FOUND: 'no se encontró el usuario',
  USERS_COGNITO_USER_POOL_ADD_ON_NOT_ENABLED: 'error al activar',
  USERS_COGNITO_USER_POOL_TAGGING: 'error al actualizar la información',
  USERS_HEADER_USER_ID_INVALID: 'el ID de usuario es inválido',
  USERS_COLLABORATOR_DUPLICATED: 'el colaborador ya pertenece a tu equipo',
  USERS_RECAPTCHA_INVALID_TOKEN: 'el token de reCAPTCHA es inválido',
  USERS_ROLE_EMPTY: 'debes agregar un rol',
  USERS_CAN_NOT_ADD_YOURSELF_TO_YOUR_OWN_TEAM:
    'no puedes agregarte a ti mismo como miembro de tu equipo',
  SEATS_HOLD_NAME_ALREADY_EXISTS: 'El nombre del hold ya existe',
  SEATS_NOT_ENOUGH_TICKET_AVAILABILITY: 'No hay suficiente disponibilidad',
  SEATS_SEATSIO_GENERAL_ERROR:
    'error al borrar este hold, alguno de los asientos apartados puede ya haber sido utilizado',
  SOMETHING_WRONG: 'Algo salió mal, inténtalo más tarde',
  RSVP_INVALID_USER: 'Usuario no válido',
  RSVP_HOLD_NOT_FOUND: 'No se encontró el hold',
  RSVP_INVALID_BODY_REQUEST: 'Petición inválida',
  RSVP_INVALID_EVENT_ID: 'Evento inválido',
  RSVP_LINKS_NOT_FOUND: 'No se encontraron links',
  RSVP_LINK_GROUP_NAME_ALREADY_EXISTS: 'El nombre del grupo ya existe',
  RSVP_INVALID_LINK_STATUS: 'Estatus de link inválido',
  RSVP_MAX_PURCHASE_EXCEEDED: 'Se excedió la cantidad máxima de compra',
  RSVP_INVALID_AMOUNT: 'Cantidad inválida',
  RSVP_INVALID_MAX_PURCHASE: 'Máximo de compra inválido',
  RSVP_INVALID_START_DATE: 'Fecha de inicio inválida',
  RSVP_INVALID_END_DATE: 'Fecha de fin inválida',
  CAMPAIGN_SERVICE_UNKNOWN_ERROR: 'Hubo un error con el servicio',
  CAMPAIGN_TESTING_ERROR: 'Error al probar la campaña',
  CAMPAIGN_BOOKING_NOT_FOUND: 'No se encontró la reservación',
  CAMPAIGN_TICKET_NOT_FOUND: 'No se encontró el boleto',
  CAMPAIGN_EVENT_NOT_FOUND: 'No se encontró el evento',
  CAMPAIGN_BILLBOARD_NOT_FOUND: 'No se encontró la cartelera',
  CAMPAIGN_QUERY_PROCESS: 'Error en la consulta',
  CAMPAIGN_SCAN_PROCESS: 'Error al escanear',
  CAMPAIGN_EVENT_ID_URL_PARAMS: 'No se recibió el ID del evento',
  CAMPAIGN_BOOKING_ID_URL_PARAMS: 'No se recibió el ID de la reservación',
  CAMPAIGN_KAFKA_MESSAGE_NIL: 'Error de proceso',
  CAMPAIGN_KAFKA_UNMARSHAL_MESSAGE: 'Error de proceso',
  CAMPAIGN_USER_ID_URL_PARAMS: 'No se recibió el ID del usuario',
  CAMPAIGN_HEADER_SUPER_ADMIN: 'No se recibió el ID del superadmin',
  CAMPAIGN_CAMPAIGN_ID_URL_PARAMS: 'No se recibió el ID de la campaña',
  CAMPAIGN_AD_ID_URL_PARAMS: 'No se recibió el ID del anuncio',
  CAMPAIGN_NOT_EXIST: 'La campaña no existe',
  CAMPAIGN_STATUS_NOT_EXIST: 'El estatus de la campaña no existe',
  CAMPAIGN_START_DATE: 'La fecha de inicio de la campaña no es válida',
  CAMPAIGN_OWNER_TYPE_NOT_EXIST: 'El evento o cartelera no existe',
  CAMPAIGN_END_DATE_LESS_THAN_START_DATE:
    'La fecha de fin es menor a la de inicio',
  CAMPAIGN_END_DATE_MORE_THAN_EVENT_OR_BILLBOARD_END_DATE:
    'La fecha de fin es mayor a la del evento o cartelera',
  CAMPAIGN_START_DATE_AFTER_EVENT_OR_BILLBOARD_START_DATE:
    'La fecha de inicio es después de la del evento o cartelera',
  CAMPAIGN_EXTERNAL_BUDGET_LESS_THAN_ZERO: 'El presupuesto es menor a cero',
  CAMPAIGN_EXTERNAL_BUDGET_SUBTRACT: 'Error al restar el presupuesto',
  CAMPAIGN_INTERNAL_BUDGET_SUBTRACT: 'Error al restar el presupuesto interno',
  CAMPAIGN_INTERNAL_BUDGET_LESS_THAN_ZERO:
    'El presupuesto interno es menor a cero',
  CAMPAIGN_EXTERNAL_BUDGET_MORE_THAN_LIMIT: 'El presupuesto es mayor al límite',
  CAMPAIGN_EXTERNAL_BUDGET_LESS_THAN_MIN: 'El presupuesto es menor al mínimo',
  CAMPAIGN_AD_IMG1_RATIO:
    'La relación de aspecto de la imagen cuadrada no es válida',
  CAMPAIGN_AD_IMG2_RATIO:
    'La relación de aspecto de la imagen horizontal no es válida',
  CAMPAIGN_AD_IMG1_FORMAT: 'El formato de la imagen cuadrada no es válido',
  CAMPAIGN_AD_IMG2_FORMAT: 'El formato de la imagen horizontal no es válido',
  CAMPAIGN_AD_IMG1_KEY: 'No se encontró la imagen cuadrada',
  CAMPAIGN_AD_IMG2_KEY: 'No se encontró la imagen horizontal',
  CAMPAIGN_BUDGET_USER_BUDGET_ALREADY_EXIST:
    'El presupuesto del usuario ya existe',
  CAMPAIGN_BUDGET_USER_BUDGET_NOT_FOUND:
    'No se encontró el presupuesto del usuario',
  CAMPAIGN_BUDGET_TRANSACTION_TYPE_NOT_EXIST:
    'El tipo de transacción no existe',
  CAMPAIGN_BUDGET_TRANSACTION_ORIGIN_NOT_EXIST:
    'El origen de la transacción no existe',
  CAMPAIGN_BUDGET_TRANSACTION_NOT_ENOUGH_BALANCE: 'No hay suficiente saldo',
  CAMPAIGN_BUDGET_TRANSACTION_GMV_OR_USER_ACTION_NOT_NEED_CARD:
    'No se necesita tarjeta para la transacción',
  CAMPAIGN_NOT_ENOUGH_INTERNAL_BUDGET: 'No hay suficiente presupuesto interno',
  CAMPAIGN_NOT_ENOUGH_EXTERNAL_BUDGET: 'No hay suficiente presupuesto',
  CAMPAIGN_STATUS_REVIEW: 'La campaña está en revisión',
  CAMPAIGN_STATUS_STOPPED: 'La campaña está detenida',
  CAMPAIGN_STATUS_FINALIZED: 'La campaña está finalizada',
  CAMPAIGN_STATUS_ACTIVE: 'La campaña está activa',
  CAMPAIGN_IS_NOT_FINALIZED: 'La campaña no está finalizada',
  CAMPAIGN_EDIT_STATUS: 'No se puede editar la campaña',
  CAMPAIGN_AD_STATUS_STOPPED: 'El anuncio está detenido',
  CAMPAIGN_IMG_NAME_EMPTY: 'El nombre de la imagen está vacío',
  CAMPAIGN_STATUS_IS_ACTIVE: 'La campaña está activa',
  CAMPAIGN_IMG1_NOT_FOUND: 'No se encontró la imagen cuadrada',
  CAMPAIGN_IMG2_NOT_FOUND: 'No se encontró la imagen horizontal',
  CAMPAIGN_NO_ACTIVE_ADS: 'No hay anuncios activos',
  CAMPAIGN_AD_STATUS_IN_REVIEW: 'El anuncio está en revisión',
  CAMPAIGN_AD_STATUS_REFUSED: 'El anuncio fue rechazado',
  CAMPAIGN_AD_STATUS_DRAFT: 'El anuncio está en borrador',
  CAMPAIGN_BUDGET_EXTERNAL_ZERO: 'El presupuesto es cero',
  CAMPAIGN_AD_STATUS_NOT_EXIST: 'El estatus del anuncio no existe',
  CAMPAIGN_BUDGET_EXCEEDED: 'El presupuesto fue excedido',
  CAMPAIGN_DOESNT_HAVE_ADS: 'La campaña no tiene anuncios',
  CAMPAIGN_AD_NOT_EXIST: 'El anuncio no existe',
  CAMPAIGN_EXIST_OWNER_CAMPAIGN: 'El evento o cartelera ya tiene una campaña',
  CAMPAIGN_ORGANIZER_CANNOT_RECEIVE_GMV: 'El organizador no puede recibir GMV',
  CAMPAIGN_SEASON_IS_ABOUT_TO_END: 'La cartelera está por finalizar',
  CAMPAIGN_AD_NO_CHANGES: 'No hay cambios a realizar en el anuncio',
  CAMPAIGN_INIT_GMV: 'La campaña ya ha sido inicializada con GMV',
  check: {
    undefined: 'Algo salió mal, inténtalo más tarde',
    0: 'Ocurrió un error desconocido',
    3: 'Hace falta el id del evento principal',
    7: 'No existe el evento o el staff no tiene acceso a ese evento',
    19: 'No hay registro del boleto del asistente en base de datos',
    20: 'El evento al que se busca ingresar no coincide con el del boleto del asistente',
    21: 'El boleto ha sido usado anteriormente para acceder al mismo checkpoint',
    22: 'El boleto ha sido usado anteriormente para acceder por un checkpoint del mismo grupo',
    23: 'El boleto del asistente no tiene permisos para entrar por ese checkpoint',
    24: 'El staff no tiene permisos para usar el checkpoint',
    27: 'El boleto del asistente ha sido cancelado',
    30: 'Se permitirá el registro de acceso al evento a partir de la fecha de inicio',
    39: 'No se puede salir de un evento si no se ha entrado',
    40: 'El evento principal no puede ser mezclado con el mismo',
    54: 'Ocurrió un error al realizar merge o unmerge de eventos.',
    56: 'Boleto con acceso rechazado',
  },
  checkout: {
    undefined: 'Algo salió mal, inténtalo más tarde',
    0: 'Ocurrió un error desconocido',
    23: 'Ocurrió un error desconocido',
    24: 'Ocurrió un error desconocido',
    39: 'No se puede salir de un evento si no se ha entrado',
    40: 'El evento principal no puede ser mezclado con el mismo',
  },
};
